<template>
  <v-card :loading="loading" outlined>
    <v-system-bar>Übersicht</v-system-bar>
    <v-list v-if="$vuetify.breakpoint.xs">
      <v-data-iterator
        disable-pagination
        hide-default-footer
        :headers="headers"
        :items="items"
        item-key="id"
        :item-class="() => 'clickable'"
        sort-by="student"
        disable-sort
        disable-filtering
      >
        <v-list-item
          v-for="item in items"
          :key="item.name"
          :to="{
            name: 'AbsencesOverviewStudent',
            params: { id: item.student.id },
          }"
        >
          <v-list-item-avatar
            ><PortraitImage :value="item.student"></PortraitImage
          ></v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              ><PersonName :value="item.student"></PersonName
            ></v-list-item-title>
            <v-list-item-subtitle>
              offen {{ item.pendingLessons }} | E {{ item.excusedLessons }} | U
              {{ item.unexcusedLesssons }} | HT {{ item.halfDays }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-badge
              v-if="item.warningLevel > 0"
              overlap
              :content="item.absentLessons"
              :color="item.warningLevel == 1 ? 'warning' : 'danger'"
            >
              <v-icon>mdi-alert</v-icon>
            </v-badge></v-list-item-action
          >
        </v-list-item>
      </v-data-iterator>
    </v-list>
    <v-data-table
      v-else
      disable-pagination
      hide-default-footer
      :headers="headers"
      :items="items"
      :item-class="() => 'clickable'"
      sort-by="student"
      @click:row="
        (el) =>
          $router.push({
            name: 'AbsencesOverviewStudent',
            params: { id: el.student.id },
          })
      "
    >
      <template v-slot:item.student="{ item }">
        <PersonItem small :value="item.student" />
      </template>
      <template v-slot:item.absentLessons="{ item }">
        <v-badge
          v-if="item.warningLevel > 0"
          overlap
          :content="item.absentLessons"
          :color="item.warningLevel == 1 ? 'warning' : 'danger'"
        >
          <v-icon>mdi-alert</v-icon>
        </v-badge>
      </template>
      <template v-slot:footer
        ><TableFooter
          :items="items"
          label="Schüler:innen"
          labelSingular="Schüler:in"
      /></template>
    </v-data-table>
    <router-view @updated="fetchData()" :schoolClass="schoolClass" />
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import PersonItem from "common/components/PersonItem.vue";
import PersonName from "common/components/PersonName.vue";
import PortraitImage from "common/components/PortraitImage.vue";
import TableFooter from "common/components/TableFooter.vue";
import { comparePeople } from "common/utils/people.js";
export default defineComponent({
  components: {
    PersonItem,
    PersonName,
    PortraitImage,
    TableFooter,
  },
  props: ["schoolClass", "term"],
  data() {
    return {
      items: [],
      loading: false,
      headers: [
        {
          text: "Schüler:in",
          value: "student",
          sort: (a, b) => comparePeople(a, b),
        },
        {
          text: "offen",
          value: "pendingLessons",
        },
        {
          text: "E",
          value: "excusedLessons",
        },
        {
          text: "U",
          value: "unexcusedLessons",
        },
        {
          text: "HT",
          value: "halfDays",
        },
        {
          text: "",
          value: "absentLessons",
        },
      ],
    };
  },
  watch: {
    schoolClass() {
      this.fetchData();
    },
    term() {
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      if (!this.term || !this.schoolClass) return;
      this.loading = true;

      this.items = await this.apiList({
        resource: "absence/overview",
        query: `schoolClass=${this.schoolClass.id}&term=${this.term.id}`,
      });
      this.loading = false;
    },
  },
});
</script>
